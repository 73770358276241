<template>

  <v-container
    id="plans"
    fluid
    tag="section"
    class="pt-0 pb-0"
  >
    <v-row
      class="pt-0 pb-0"
      v-show="showTopTenPanel"
    >
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="pt-0"
      >
        <plans-top-ten-pie-chart v-on:plan-selected="topTenPlanSelected" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="8"
        class="pt-0"
      >
        <plans12-months-line-chart :plan-name="planFilter" :group-name="groupPlanFilter" />
      </v-col>
    </v-row>
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="props">
        <v-chip
          :color="!props.item.disabled?'':'#FCDBE6'"
          :text-color="!props.item.disabled?'':'#EB3573'"
          @click="handleBreadCrumbClick(props.item.code)"
        >
          {{ props.item.text }}
        </v-chip>
      </template>
    </v-breadcrumbs>
    <plans-equipment-device-summary-table
      v-show="showEquipmentSummary"
      :plan-filter="planFilter"
      v-on:filter-equipment="filterEquipment"
      v-on:all-plans="allPlans"
      v-on:group-plans="groupPlans"
      v-on:plan-selected="topTenPlanSelected"
    />
    <plans-equipment-data-table
      v-show="showSnTable"
      :equipment-filter="equipmentFilter"
      v-on:filter-sn="filterSn"
    />
    <service-number-page
      v-if="showSnDetail"
      :sn-data="serviceNumberFilter.sn"
    />
  </v-container>
</template>

<script>
  import PlansTopTenPieChart from '@/views/blitz/components/apex/PlansTopTenPieChart'
  import Plans12MonthsLineChart from '@/views/blitz/components/apex/Plans12MonthsLineChart'
  import PlansEquipmentDeviceSummaryTable from '@/views/blitz/components/PlansEquipmentDeviceSummaryTable'
  import PlansEquipmentDataTable from '@/views/blitz/components/PlansEquipmentDataTable'
  import ServiceNumberPage from '@/views/blitz/pages/ServiceNumberPage'
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('plan')
  export default {
    name: 'Plans',
    components: {
      PlansTopTenPieChart,
      Plans12MonthsLineChart,
      PlansEquipmentDeviceSummaryTable,
      PlansEquipmentDataTable,
      ServiceNumberPage,
    },
    computed: {
      ...mapGetters([
        'equipmentTableFilter',
      ]),
      showSnTableFilter: function () {
        if (this.equipmentFilter.category === 'deviceType') {
          return true
        }
        if (this.equipmentFilter.category === 'make') {
          return true
        }
        if (this.equipmentFilter.category === 'deviceName') {
          return true
        }
        return false
      },
    },
    watch: {
      equipmentTableFilter: {
        handler () {
          console.log('refreshRequired changed to category ' + this.equipmentTableFilter.category + ' label ' + this.equipmentTableFilter.label)
          this.showEquipmentSummary = true
          this.showSnTable = false
          this.showSnDetail = false
          // Reset brumbs..
          this.crumbs = this.summary_crumbs
        },
      },
      snTableFilter: {
        handler () {
          console.log('refreshRequired changed to SN Table category ' + this.snTableFilter.category + ' label ' + this.snTableFilter.label)
          // Reset brumbs..
          this.showEquipmentSummary = false
          this.showSnTable = true
          this.showSnDetail = false
          this.crumbs = this.sn_table_crumbs
        },
      },
    },
    data () {
      return {
        breadFilter: false,
        planFilter: null,
        groupPlanFilter: null,
        equipmentFilter: { },
        serviceNumberFilter: null,
        showEquipmentSummary: true,
        showSnTable: false,
        showSnDetail: false,
        showTopTenPanel: true,
        crumbs: [
          {
            text: 'Equipment Plan Summary',
            disabled: false,
            code: 'equipmentSummary',
            href: 'breadcrumbs_dashboard',
          },
        ],
        summary_crumbs: [
          {
            text: 'Equipment Plan Summary',
            disabled: false,
            code: 'equipmentSummary',
            href: 'breadcrumbs_dashboard',
          },
        ],
        sn_table_crumbs: [
          {
            text: 'Equipment Plan Summary',
            disabled: false,
            code: 'equipmentSummary',
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service List',
            code: 'serviceNumbers',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },
        ],
        sn_crumbs: [
          {
            text: 'Equipment Plan Summary',
            disabled: false,
            code: 'equipmentSummary',
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service List',
            code: 'serviceNumbers',
            disabled: false,
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service Detail',
            code: 'sn',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },
        ],
      }
    },
    mounted () {
      // this.$gtag.event('mounted', { page: 'equipment' })
      // this.$gtag.event('login', { method: 'Google' })
      this.$gtag.pageview({
        page_path: '/plans',
      })

      this.allPlans();
    },
    methods: {
      filterSn (snFilter) {
        console.log('filterSn', snFilter)
        this.serviceNumberFilter = snFilter
        this.showEquipmentSummary = false
        this.showSnTable = false
        this.showSnDetail = true
        this.showTopTenPanel = false
        this.crumbs = this.sn_crumbs
      },
      filterEquipment (equipmentFilter) {
        console.log('filterEquipment', equipmentFilter)
        this.equipmentFilter = equipmentFilter
        this.showEquipmentSummary = false
        this.showSnTable = true
        this.showSnDetail = false
        this.crumbs = this.sn_table_crumbs
      },
      topTenPlanSelected (plan) {
        console.log('topTenPlanSelected ', plan)
        this.planFilter = plan
        console.log('Plan Filter  ------> ', this.planFilter)
      },
      handleBreadCrumbClick (code) {
        console.log('handleBreadCrumbClick value is ' + code)
        if (code === 'equipmentSummary') {
          this.showEquipmentSummary = true
          this.showSnTable = false
          this.showSnDetail = false
          this.showTopTenPanel = true
          this.crumbs = this.summary_crumbs
        }
        if (code === 'serviceNumbers') {
          this.showEquipmentSummary = false
          this.showSnTable = true
          this.showSnDetail = false
          this.showTopTenPanel = true
          this.crumbs = this.sn_table_crumbs
        }
        if (code === 'sn') {
          this.showEquipmentSummary = false
          this.showSnTable = false
          this.showSnDetail = true
          this.showTopTenPanel = false
          this.crumbs = this.sn_crumbs
        }
        // store.dispatch('equipment/updateSnFilter', {}) // reset the SN filter
      },
      allPlans(){
        console.log('All Plans fired')
        this.planFilter = {planName : 'All Plans', planType: 'ALL'}
      },
      groupPlans(plans){
        console.log('Group plan by, ', plans)
        this.groupPlanFilter = plans
      }
    }
  }
</script>

<style scoped>

</style>
