<template>
  <v-card
    height="100%"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      Top Ten Plan Type
    </v-card-title>
    <div style="padding-right:10px; max-width: 650px;">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="pieSeries"
      />
    </div>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import { findMonthlyTopTen } from '@/api/PouchDaoReport'
  import { appConfig } from '@/appConfig'

  export default {
    name: 'PlanTopTenPieChart',
    data () {
      return {
        topTenData: [],
        pieSeries: [],
        chartOptions: {
          chart: {
            events: {
              dataPointSelection: (e, chart, opts) => {
                // you can call Vue methods now as "this" will point to the Vue instance when you use ES6 arrow function
                this.topTenPointSelected(opts)
              },
            },
          },
          plotOptions: {
            pie: {
              customScale: 0.9,
              expandOnClick: false,
              donut: {
                size: '60%',
                labels: {
                  show: true,
                },
              },
            },
          },
          noData: {
            text: 'No data',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#BEC932',
              fontSize: '12px',
              fontFamily: undefined,
            },
          },
          dataLabels: {
            enabled: true,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            offsetX: -30,
            offsetY: 0,
            formatter: function (seriesName, opts) {
              const n = 30 // length of word
              const trimmedName = (seriesName.length > n) ? seriesName.substr(0, n - 1) + '&hellip;' : seriesName
              return [trimmedName]
            },
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              }
            },
            hover: {
              filter: {
                type: 'lighten',
                value: 0,
              }
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'none',
                value: 0,
              }
            },
          },
          colors: appConfig.colors,
          fill: {
            type: 'gradient',
          },
        },
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        flexiPlanSummaryList: 'flexiPlanSummaryList',
        shareablePlanSummaryList: 'shareablePlanSummaryList',
        nonShareablePlanSummaryList: 'nonShareablePlanSummaryList',
      }),
    },
    mounted () {
      console.log('load the top ten pie chart')
      this.loadTopTenChart()
    },
    methods: {
      topTenPointSelected (opts) {
        const planName = this.chartOptions.labels[opts.dataPointIndex]
        console.log('topTenPointSelected label ' + this.chartOptions.labels[opts.dataPointIndex] + ' value ' + this.pieSeries[opts.dataPointIndex])
        // find the plan from top ten...
        const selectedPlan = this.topTenData.filter(plan => plan.planName === planName)
        console.log('topTenPointSelected  selectedPlan is ', selectedPlan[0])
        this.$emit('plan-selected', { ...selectedPlan[0] })
      },
      loadTopTenChart () {
        console.log('read the plan info and load top ten...')
        // need to combine flexplan and shared plan...
        const flexiPlanList = this.flexiPlanSummaryList
        const shareablePlanList = this.shareablePlanSummaryList
        const nonShareablePlanList = this.nonShareablePlanSummaryList
        // then do a top ten....
        let planChartData = []
        planChartData.push(...flexiPlanList.map(plan => ({ planType: 'FLEXI', planName: plan.planName, qty: plan.qty })))
        planChartData.push(...shareablePlanList.map(plan => ({ planType: 'SHARABLE', planName: plan.planName, qty: plan.qty })))
        planChartData.push(...nonShareablePlanList.map(plan => ({ planType: 'NON SHARABLE', planName: plan.planName, qty: plan.qty })))
        // now to a sort and top ten....
        //
        this.topTenData = planChartData.sort((plan1, plan2) => plan2.qty - plan1.qty)
          .slice(0, 10)

        console.log('top list is ', this.topTenData)
        this.refreshTopTen(this.topTenData)
      },
      refreshTopTen (data) {
        console.log(' refreshTopTen ')
        const chartData = []
        const labels = []
        data.forEach(pieData => {
          labels.push(pieData.planName)
          chartData.push(pieData.qty)
        })
        // console.log(chartData)
        this.pieSeries = chartData // copy the array...
        this.chartOptions = {
          labels: labels,
        }
        //this.$emit('plan-selected', { ...data[0] })
      },
    }
  }
</script>

<style scoped>

</style>
