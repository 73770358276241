<template>
  <v-card
    height="100%"
    align="center"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      {{ planNameDescription }}
    </v-card-title>
    <apexchart
      type="area"
      height="330"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { findLast12MonthsDomesticDataPlanUsageTotal } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'
  import moment from 'moment'

  export default {
    name: 'Plans12MonthsLineChart',
    props: {
      planName: {
        type: Object,
      },
      groupName: {
        type: Object,
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
    },
    data () {
      return {
        planNameDescription: null,
        series: [],
        chartOptions: {
          chart: {
            colors: ['#E91E63'],
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.7,
              opacityTo: 0.4,
              stops: [0, 100],
              colorStops: [],
            },
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              offsetX: -5,
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
          },
          yaxis: [
            {
              seriesName: 'Data Usage',
              labels: {
                style: {
                  color: '#4D4D4D',
                  fontSize: '11px',
                },
                formatter: function (value) {
                  return Utils.readableBytes(value)
                },
              },
              title: {
                text: 'Data Usage (Mb)',
                offsetX: 0,
                style: {
                  color: '#959494',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
        },
      }
    },
    watch: {
      planName: 'planNameChanged',
      groupName: 'planNameChanged'
    },
    methods: {
      planNameChanged (planName) {
        console.log('planNameChanged to ', planName)
        this.planNameDescription = planName.planName
        this.$gtag.event('plan12MonthsLineChart', {
          event_category: 'planSelected',
          event_label: planName,
        })
        // get 12 months domestic data for plan name....
        findLast12MonthsDomesticDataPlanUsageTotal(this.customerInfo.earliest, this.customerInfo.latest, planName.planName).then(result => {
          // console.log('findLast12MonthsDomesticDataPlanUsageTotal returns ', result)
          let chartData = []
          let maxUseCapacity = 0
          result.totalUsage.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total) })
            if (dataCall.total > maxUseCapacity) {
              maxUseCapacity = dataCall.total
            }
          })
          this.updateLineChartData(result.totalPlanCapacity, maxUseCapacity, chartData)
        })
      },
      updateLineChartData (totalPlanCapacity, maxUserCapacity, chartData) {
        const maxCapacity = Math.round((maxUserCapacity > totalPlanCapacity ? maxUserCapacity : totalPlanCapacity))
        // console.info('updateLineChartData ', chartData)
        let chartOptions = {
          yaxis: {
            max: maxCapacity,
            labels: {
              style: {
                color: '#4D4D4D',
                fontSize: '11px',
              },
              formatter: function (value) {
                return Utils.readableBytes(value)
              },
            },
          },
        }
        if (totalPlanCapacity > 0) {
          chartOptions = {
            ...chartOptions,
            ...{
              annotations: {
                yaxis: [
                  {
                    y: Math.round(totalPlanCapacity),
                    borderColor: '#e3002d',
                    label: {
                      borderColor: '#e3005b',
                      style: {
                        color: '#fff',
                        background: '#6f0124'
                      },
                      text: 'Plan capacity ' + Utils.readableBytes(totalPlanCapacity)
                    }
                  }
                ]
              },
            }
          }
        }
        this.chartOptions = chartOptions
        this.series = [{
          name: 'DOMESTIC USAGE',
          data: chartData,
        }]
      }
    }
  }
</script>

<style scoped>

</style>
